<template>
  <data-list
    ref="dataList"
    class="content md"
    add-btn=""
    name="日志"
    hide-action
    :custom-url="customUrl"
    :url="url"
    :query="query"
    :columns="columns">
    <template v-slot:title="{total}">
      <span>共{{total}}条记录</span>
    </template>
    <template v-slot:query="{form}">
      <a-form-model-item label="时间范围">
        <a-range-picker
          v-model="form.time"
          allowClear
          :disabled-date="disabledDate"
          :allow-clear="false"
          :placeholder="['开始时间', '结束时间']"
          format="YYYY-MM-DD"
          value-format="YYYY-MM-DD"
          @change="getList"/>
      </a-form-model-item>
      <a-form-model-item label="">
        <a-select
          v-model="form.type"
          allowClear
          :options="logTypes"
          placeholder="选择日志类型查询"
          style="width: 180px"
          @change="getList"></a-select>
      </a-form-model-item>
    </template>
  </data-list>
</template>

<script>
    import moment from 'moment';
    import {getLogTypeText, logTypes} from "../data/log";
    import {getRoleTypeText} from "../common/constant/org";

    export default {
        name: "SystemLog",
        data() {
            return {
                url: "/admin-log",
                query: "&sort=-create_time",
                columns: [
                    { title: '时间', dataIndex: 'create_time' },
                    { title: '人员', dataIndex: 'admin', customRender: text => text ? text.name : "--" },
                    { title: '角色', dataIndex: 'role', customRender: (text, record) => record.admin ? getRoleTypeText(record.admin.role) : "--" },
                    { title: '类型', dataIndex: 'type', customRender: getLogTypeText },
                ],
                logTypes
            }
        },
        methods: {
            disabledDate(current) {
                return current && current > moment().endOf('day');
            },
            customUrl(url, form) {
                let res = "";
                if(form.time && form.time.length == 2) {
                    res += `&filter[create_time][gte]=${form.time[0]} 00:00:00&filter[create_time][lte]=${form.time[1]} 23:59:59`;
                }
                if(form.type) {
                    res += `&filter[type]=${form.type}`
                }
                return res;
            },
            getList() {
                this.$refs.dataList.getList();
            }
        }
    }
</script>

<style scoped>

</style>
