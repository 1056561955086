export const logTypes = [
    { value: 1, label: "登陆系统" },
    { value: 2, label: "审批" },
    { value: 3, label: "修改密码" },
];

export const getLogTypeText = function (type) {
    const t = logTypes.find(item => item.value == type);
    return t ? t.label : "";
}
